<template> 
    <v-card>
        <v-card-title>
            <!-- Titulo -->
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-divider style="border:none!important;" ></v-divider>
            <v-btn class="pa-6" icon to="/app" >
                <v-icon color="#ff5000" large> mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-data-table expand-icon :headers="headers"  :search="buscar" :items="users" class="elevation-0">
                <template v-slot:top>
                    <v-text-field class="my-6" label="Buscar" append-icon="mdi-magnify" v-model="buscar"></v-text-field>
                </template>
                <template slot="no-data">
                    No existen usuarios registrados
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialog" max-width="500px">
                <!-- Titulo del dialogo -->
                <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <!-- Formulario -->
                        <v-form ref="form" v-model="valid">
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.name" label="Nombre"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.email" label="Correo Electrónico"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.password" type="password" label="Password"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <!-- Cancelar y Guardar -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="blue darken-1" text :disabled="!valid" @click="save">Guardar</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog> 
        </v-card-text>
        <v-btn bottom color="#ff5000" dark fab fixed right @click="dialog = true">
            <v-icon color="white">  mdi-plus </v-icon>
        </v-btn> 
    </v-card>
</template>

<script>
import axios from "axios"
export default {
    data:()=>({
        buscar:'',
        valid: true,       
        dialog: false,
        editedIndex: -1,
        headers:[
            {text: 'Nombre', value: 'name'},
            {text: 'Email', value:'email'},
            {text: 'Acciones', value: 'actions', sortable: false },
        ],
        editedItem: {
            name:'',
            email:'',
            password:''
        }
    }),
    computed:{
        users:{
            get(){
                return this.$store.state.user.users.filter(user=>user.id>2);
            }
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
        } ,
    },
    created(){
        this.$store.dispatch('user/getUsers')
    },
    methods:{
        editItem (item) {
            this.editedIndex = this.users.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        /* cerrar dialogos */
        close () {
            Object.keys(this.altaConcepto).forEach(key => {
                return (this.altaConcepto[key] = "");
            }); 
            this.editedIndex = -1
            this.dialog = false
            this.$nextTick(() => {
                this.$store.dispatch('user/getUsers')
            })
        },
        deleteItem (item) {
            let id = item.id
            if (confirm('¿Seguro que deseas borrar este usuario?')) {
                axios.delete('https://unoweb.uno/api/v1/user/borrar/'+id).then(resp => {
                    this.close()
                })
            }
        },
        save () {
            if (this.editedIndex > -1) {                
                    axios.put('https://unoweb.uno/api/v1/user/actualizar',Object.assign(this.users[this.editedIndex], this.editedItem)).then(resp => {
                        this.close()
                    })
            } else {
                axios.post('https://unoweb.uno/api/v1/user/guardar',Object.assign(this.editedItem)).then(resp => {
                    this.close()
                })
            }
        },
    },
}
</script>
<style>
    .v-data-table-header.v-data-table-header-mobile{
        display:none!important;
    }
</style>